/*
 * External dependencies.
 */
import React from 'react';
import cx from 'classnames';

const Grid: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <div className={cx(['grid', className])}>{children}</div>
);

const GridCol: React.FC<{
  className?: string;
  children: React.ReactNode;
  size?: number;
}> = ({ children, className, size }) => (
  <div
    className={cx([
      'grid__col',
      className,
      { 'grid__col--1of2': size === 2 },
      { 'grid__col--1of3': size === 3 },
    ])}
  >
    {children}
  </div>
);

export { Grid, GridCol };
