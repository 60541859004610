/**
 * External dependencies.
 */
import React from 'react';
import cx from 'classnames';

const Box: React.FC<{
  className?: string;
  title?: string;
  subtitle?: string;
  image?: string;
  signals?: string;
  curator?: string;
  originator?: string;
  url?: string;
  offset?: string
}> = ({
  className,
  title,
  subtitle,
  image,
  signals,
  curator,
  originator,
  url,
  offset = '0px'
}) => {
  return (
    <div className={cx('box', className)} style={{ marginTop: offset }}>
      <div className="gradient" />
      <a className="box__link" href={url} target="_blank" rel="noreferrer"></a>
      {image && (
        <div
          className="box__image"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      )}

      <div className="box__content">
        {title && <h3>{title}</h3>}

        {subtitle && <p>{subtitle === 'Self' ? 'Identity' : subtitle}</p>}

        <ul className="box__features">
          <li>
            <h6>Signals</h6>

            {signals && <span>{signals}</span>}
          </li>

          <li>
            <h6>Curator</h6>

            {curator && <span>{curator}</span>}
          </li>

          <li>
            <h6>Originator</h6>

            {originator && <span>{originator}</span>}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Box;
