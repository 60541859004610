/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Parallax } from 'react-scroll-parallax';

/**
 * Internal dependencies.
 */
import Header from 'blocks/header/header';
import Footer from 'blocks/footer/footer';

const IMAGES = [
  require('assets/images/start-pattern1.jpeg'),
  require('assets/images/start-pattern2.jpeg'),
  require('assets/images/start-pattern3.jpeg'),
  require('assets/images/start-pattern4.jpeg'),
  require('assets/images/start-pattern5.jpeg')
]

const LayoutBase: React.FC = ({ children }) => {
  const [animated, setAnimated] = useState<boolean>(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <div className="wrapper">
      <Header />

      {children}

      <Footer />

      <Parallax className="widget-images" translateY={[0, -20]} speed={-50}>
        <div className="widget-images">
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
          <img src={require(`assets/images/widgets/${Math.floor(Math.random() * 28) + 1}.png`)} alt="" />
        </div>
      </Parallax>
      <div className="widget-images-bg">
          <img src={require(`assets/images/fixed_l1_bg_${Math.floor(Math.random() * 4) + 1}.png`)} alt="" />
        </div>

      <div className={cx('load-animation', { 'is-animated': animated })}>
        <div
          className="load-animation__image"
          style={{
            backgroundImage: `url(${IMAGES[Math.floor(Math.random() * 5)]})`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default LayoutBase;
