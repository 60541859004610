import { gql } from "@apollo/client";

export const CurationsCollection = gql`
query GetLaunchList {
   curationCollection(limit: 5) {
    items {
      title
      url
      pillar {
        name
        slug
      }
      signal
      curator
      originator
    }
  }
}
`

export const PillarSignalCollection = gql`
  query CurationCollectionQuery($where: CurationFilter) {
    curationCollection(where: $where) {
      items {
        sys {
          publishedAt
        }
        title
        url
        pillar {
          name
          slug
        }
        signal
        curator
        originator
        curatedAt
        image {
          url
        }
      }
    }
  }
`