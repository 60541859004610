/**
 * External dependencies.
 */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Draggable from "react-draggable";

/**
 * Internal dependencies.
 */
import LayoutBase from "layouts/layout-base/layout-base";
import { Section, SectionHead, SectionBody, SectionLink } from "blocks/section/section";
import { Grid, GridCol } from "blocks/grid/grid";
import Shell from "blocks/shell/shell";
import Box from "components/box/box";
import { useQuery } from "@apollo/client";
import { CurationsCollection } from "graphql/queries";

const getRandomOffset = (max: number = 100, negative?: boolean) => {
  return Math.floor(Math.random() * max) * (negative ? -1 : 1);
};

const Home: React.FC = () => {
  const { data, loading } = useQuery(CurationsCollection);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading || !data.curationCollection.items.length) {
    return <div className="spinner-loader">Loading…</div>;
  }

  const { items } = data.curationCollection;

  return (
    <LayoutBase>
      <Section className="section--hero">
        <SectionHead>
          <h1>
            <span>Daily</span> Signals
          </h1>

          <strong>{new Date().toLocaleDateString("en-GB").split("/").join(".")}</strong>
        </SectionHead>

        <div className="boxes boxes--size1">
          <Grid>
            {items?.slice(0, 2).map((item: any, index: number) => {
              return (
                <Draggable key={index} handle=".gradient">
                  <div className="grid__col grid__col--1of3">
                    <Box
                      className="box--small"
                      title={item.pillar.name}
                      subtitle={item.title}
                      url={item.url}
                      signals={item.signal}
                      curator={item.curator}
                      originator={item.originator}
                      offset={`${getRandomOffset(50, true)}px`}
                    />
                  </div>
                </Draggable>
              );
            })}
          </Grid>
        </div>
      </Section>

      <Section className="section--hero-alt">
        <SectionHead>
          <h1>
            <span>Daily</span> Signals
          </h1>

          <strong>{new Date().toLocaleDateString("en-GB").split("/").join(".")}</strong>
        </SectionHead>

        <SectionBody>
          <div className="boxes boxes--small boxes--size2">
            <Grid>
              {items?.slice(2, 5).map((item: any, index: number) => {
                return (
                  <Draggable key={index} handle=".gradient">
                    <div className="grid__col grid__col--1of3">
                      <Box
                        className="box--small"
                        title={item.pillar.name}
                        url={item.url}
                        subtitle={item.title}
                        signals={item.signal}
                        curator={item.curator}
                        originator={item.originator}
                        offset={`${getRandomOffset()}px`}
                      />
                    </div>
                  </Draggable>
                );
              })}
            </Grid>
          </div>
        </SectionBody>
      </Section>

      <Section className="section--small">
        <SectionLink href="/">Apply to RADAR</SectionLink>
      </Section>

      <Section className="section--primary">
        <Shell>
          <SectionHead>
            <h2>See all signals</h2>
          </SectionHead>

          <SectionBody>
            <ul
              className="list-links list-links--home"
              data-aos="slide-up"
              data-aos-delay="1000"
              data-aos-duration="2000"
            >
              <li>
                <span>
                  <Link to="/trend-pillars/identity">Identity</Link> <i className="ico-dots11"></i>
                </span>
              </li>

              <li>
                <span>
                  <Link to="/trend-pillars/health-wellness">Health & Wellness</Link> <i className="ico-dots2"></i>
                </span>
              </li>

              <li>
                <span>
                  <i className="ico-dots12"></i> <Link to="/trend-pillars/tech">Tech</Link>
                </span>
              </li>

              <li>
                <span>
                  <i className="ico-dots13"></i> <Link to="/trend-pillars/creative">Creative</Link>{" "}
                  <i className="ico-dots14"></i>
                </span>
              </li>

              <li>
                <span>
                  <Link to="/trend-pillars/people-planet">People And Planet</Link> <i className="ico-dots15"></i>
                </span>
              </li>
            </ul>
          </SectionBody>
        </Shell>
      </Section>
    </LayoutBase>
  );
};

export default Home;
