/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { useLocation, useParams } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import { Section, SectionGroup, SectionContent } from 'blocks/section/section';
import { Grid, GridCol } from 'blocks/grid/grid';
import Shell from 'blocks/shell/shell';
import Box from 'components/box/box';
import { useQuery } from '@apollo/client';
import { PillarSignalCollection } from 'graphql/queries';
import { EmptyPage } from 'components/empty-page';

enum Signals {
  creative = 'Creative',
  identity = 'Identity',
  'health-wellness' = 'Health And Wellness',
  tech = 'Tech',
  'people-planet' = 'People And Planet',
}

const TrendPillars: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery(PillarSignalCollection, {
    variables: {
      where: {
        pillar: {
          slug: id,
        },
      },
    },
  });

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  /**
   * Group Items By Date
   */
  const groupItems = (items: any) => {
    const groups = items.reduce((groups: any, item: any) => {
      const date = item.sys.publishedAt.split('T')[0];

      if (!groups[date]) {
        groups[date] = [];
      }

      groups[date].push(item);

      return groups;
    }, {});
    
    let resultItems: any = [];
    let resultAllItems: any = [];

    let sorted = Object.keys(groups)
      .map((date) => {
        return {
          date,
          items: groups[date],
        };
      })
      .sort((a, b) => b.date.localeCompare(a.date));

    sorted.map((item, index) => {
      if (onToday(new Date(item.date).getDate())) {
        resultItems.push(item);
      }

      if (
        onCurrentWeek(new Date(item.date)) &&
        !onToday(new Date(item.date).getDate())
      ) {
        resultItems.push(item);
      }

      if (
        !onCurrentWeek(new Date(item.date)) &&
        !onToday(new Date(item.date).getDate())
      ) {
        resultAllItems.push(item.items);
      }

      return false;
    });

    resultItems.push({
      date: 'All',
      items: Array.prototype.concat.apply([], resultAllItems),
    });

    return resultItems;
  };

  /**
   * Check if date is today
   */
  const onToday = (date: any) => {
    return new Date().getDate() === date;
  };

  /**
   * Check if date is in this week
   */
  const onCurrentWeek = (date: any) => {
    const weekLength = 604800000;
    var lastMonday = new Date();

    lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() - 1));
    lastMonday.setHours(0, 0, 0, 0);

    const res =
      lastMonday.getTime() <= date.getTime() &&
      date.getTime() < lastMonday.getTime() + weekLength;

    return res;
  };

  if (loading) {
    return <div className="spinner-loader">Loading…</div>;
  }

  if (!data.curationCollection.items?.length) {
    return <EmptyPage pillarName={Signals[id as keyof typeof Signals]} />;
  }

  const groupedItems = groupItems(data.curationCollection.items);
  const pillarName = data.curationCollection.items[0].pillar.name;

  return (
    <LayoutAlt>
      <Section className="section--alt">
        <Shell>
          <img
            src={require('assets/images/img12.png')}
            alt="img12"
            className="section__image"
          />

          <SectionContent>
            <h1>
              <span>{pillarName}</span> Signals
            </h1>
          </SectionContent>
        </Shell>
      </Section>

      <Section className="section--boxes">
        {groupedItems?.map((item: any, index: number) => {
          return (
            <SectionGroup key={index}>
              <h3>
                {onToday(new Date(item.date).getDate()) && 'Daily Signals'}

                {onCurrentWeek(new Date(item.date)) &&
                  !onToday(new Date(item.date).getDate()) &&
                  'Signals This Week'}

                {!onCurrentWeek(new Date(item.date)) &&
                  !onToday(new Date(item.date).getDate()) &&
                  'All Signals'}

                {item.date !== 'All' && (
                  <span>
                    {new Date(item.date)
                      .toLocaleDateString('en-GB')
                      .split('/')
                      .join('.')}
                  </span>
                )}
              </h3>

              <div className="boxes boxes--def boxes--size3">
                <Grid>
                  {item.items.map((item: any, index: any) => {
                    return (
                      <Draggable key={index} handle=".gradient">
                        <div className="grid__col grid__col--1of3">
                          <Box
                            className="box--def"
                            image={item.image?.url}
                            subtitle={item.title}
                            url={item.url}
                            signals={item.signal}
                            curator={item.curator}
                            originator={item.originator}
                          />
                        </div>
                      </Draggable>
                    );
                  })}
                </Grid>
              </div>
            </SectionGroup>
          );
        })}
      </Section>
    </LayoutAlt>
  );
};

export default TrendPillars;
