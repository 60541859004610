/**
 * External dependencies.
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import Footer from 'blocks/footer/footer';

const PopupMenu: React.FC = () => {
  return (
    <div className="popup-menu">
      <div className="popup__images">
        <img src={require('assets/images/n2.png')} alt="" />

        <img src={require('assets/images/n3.png')} alt="" />

        <img src={require('assets/images/n4.png')} alt="" />

        <img src={require('assets/images/n5.png')} alt="" />
      </div>

      <div className="popup__inner">
        <h2>Menu</h2>

        <ul className="list-links list-links--primary">
          <li>
            <span>
              <Link to="/trend-pillars/identity">Identity</Link>{' '}
              <i className="ico-dots1"></i>
            </span>
          </li>

          <li>
            <span>
              <Link to="/trend-pillars/health-wellness">Health & Wellness</Link>{' '}
              <i className="ico-dots2"></i>
            </span>
          </li>

          <li>
            <span>
              <i className="ico-dots3"></i>{' '}
              <Link to="/trend-pillars/tech">Tech</Link>
            </span>
          </li>

          <li>
            <span>
              <Link to="/trend-pillars/creative">Creative</Link>{' '}
              <i className="ico-dots4"></i>
            </span>
          </li>

          <li>
            <span>
              <Link to="/trend-pillars/people-planet">People And Planet</Link>{' '}
              <i className="ico-dots5"></i>
            </span>
          </li>
        </ul>

        <div className="popup__bar">
          <ul className="list-links-alt">
            <li className="is-active">
              <a
                href="https://radarxyz.notion.site/Community-Manifesto-4258725a0a1048b0968fcd9b273a4cb8"
                target="_blank"
                rel="noreferrer"
              >
                MANIFESTO
              </a>
            </li>

            <li>
              <a
                href="https://www.notion.so/radarxyz/A-Brief-History-of-RADAR-0a9c052208d14f368272cfdf4fdb3bf2"
                target="_blank"
                rel="noreferrer"
              >
                LEARN MORE
              </a>
            </li>

            <li>
              <a
                href="https://airtable.com/shrFKUzeNpJoDU0x9"
                target="_blank"
                rel="noreferrer"
              >
                APPLY TO JOIN RADAR
              </a>
            </li>
          </ul>

          <ul className="list-links-alt">
            <li className="is-active">
              <a
                href="https://twitter.com/Radarxyz/status/1491022060209930240"
                target="_blank"
                rel="noreferrer"
              >
                CLAIM YOUR $SIGNALS
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PopupMenu;
