/**
 * Internal styles
 */
import './styles/style.scss';
import Routes from './routes';

/**
 * External dependencies
 */
import React, { useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ApolloProvider } from '@apollo/client';
import client from './apollo-client';

// import AOS from "aos"
const AOS = require('aos');

const App: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      AOS.init({
        useClassNames: true,
        initClassName: false,
        animatedClassName: 'animated',
      });
    }, 1500);
  }, []);

  return (
    <ApolloProvider client={client}>
      <ParallaxProvider>
        <Routes />
      </ParallaxProvider>
    </ApolloProvider>
  );
};

export default App;
