/*
 * External dependencies.
 */
import React from 'react';
import cx from 'classnames';

const Shell: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <div className={cx(['shell', className])}>{children}</div>
);

export default Shell;
