/*
 * External dependencies.
 */
import React from 'react';
import cx from 'classnames';

const Section: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <section className={cx('section', className)}>{children}</section>
);

const SectionHead: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <header className="section__head">
    {children}
  </header>
);

const SectionBody: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => <div className="section__body">{children}</div>;

const SectionGroup: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <div className={cx('section__group', className)}>{children}</div>
);

const SectionContent: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className="section__content">
    {children}
  </div>
);

const SectionLink: React.FC<{
  href?: string;
  children: React.ReactNode;
}> = ({ children, href }) => (
  <a href={href} className="section__link">
    {children}
  </a>
);

export {
  Section,
  SectionHead,
  SectionBody,
  SectionLink,
  SectionGroup,
  SectionContent,
};
