/**
 * External dependencies.
 */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import Logo from 'components/logo/logo';
import PopupMenu from 'blocks/popup-menu/popup-menu';

const Header: React.FC = () => {
  const [expandedMenu, setExpandedMenu] = useState<boolean>(false);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setExpandedMenu(!expandedMenu);
  };

  useEffect(() => {
    if (expandedMenu) {
      window.scrollTo(0, 0);
    }
  }, [expandedMenu]);

  useEffect(() => {
    const docBody = document.querySelector('body');
    if (docBody) {
      if (expandedMenu) {
        docBody.style.overflowY = 'hidden';
      } else {
        docBody.style.overflowY = 'scroll';
      }
    }
  }, [expandedMenu]);

  return (
    <>
      <header className={cx('header', { 'is-active': expandedMenu })}>
        <div className="shell">
          <Logo />

          <button className="header__link" onClick={handleClick}>
            Menu
            <i className="ico-burger">
              <span></span>

              <span></span>

              <span></span>
            </i>
          </button>
        </div>
      </header>

      <PopupMenu />
    </>
  );
};

export default Header;
