import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
	uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_ENV_ID}/environments/${
		process.env.REACT_APP_URL_BRANCH || "master"
	}`,
	cache: new InMemoryCache({
		resultCaching: false,
	}),
	headers: {
		Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
	},

	defaultOptions: {
		query: {
			fetchPolicy: "no-cache",
		},
	},
});

export default client;