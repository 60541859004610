/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';

/**
 * Internal dependencies.
 */
import Header from 'blocks/header/header';
import Footer from 'blocks/footer/footer';


const IMAGES = [
  require('assets/images/start-pattern1.jpeg'),
  require('assets/images/start-pattern2.jpeg'),
  require('assets/images/start-pattern3.jpeg'),
  require('assets/images/start-pattern4.jpeg'),
  require('assets/images/start-pattern5.jpeg')
]


const LayoutAlt: React.FC = ({ children }) => {
  const [animated, setAnimated] = useState<boolean>(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <div className="wrapper">
      <Header />

      {children}

      <Footer />

      <div className="widget-images widget-images--alt">
        <img src={require('assets/images/img13.png')} alt="" />

        <img src={require('assets/images/img13.png')} alt="" />

        <img src={require('assets/images/img14.png')} alt="" />
      </div>

      <div className={cx('load-animation', { 'is-animated': animated })}>
        <div
          className="load-animation__image"
          style={{
            backgroundImage: `url(${IMAGES[Math.floor(Math.random() * 5)]})`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default LayoutAlt;
