/**
 * External dependencies.
 */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import Home from './pages/home';
import TrendPillars from './pages/trend-pillars';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={Home} />

      <Route path="/trend-pillars/:id" exact component={TrendPillars} />
    </Switch>
  </Router>
);

export default Routes;
