import { Section, SectionContent } from 'blocks/section/section';
import Shell from 'blocks/shell/shell';
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import React, { FC } from 'react';

type EmptyPageProps = { pillarName: string };

export const EmptyPage: FC<EmptyPageProps> = ({ pillarName }) => {
  return (
    <LayoutAlt>
      <Section className="section--alt">
        <Shell>
          <img
            src={require('assets/images/img12.png')}
            alt="img12"
            className="section__image"
          />

          <SectionContent>
            <h1>
              <span>{pillarName}</span> Signals
            </h1>
          </SectionContent>
        </Shell>
      </Section>
      <Section className="section--boxes">
        <h2>No signals yet.</h2>
      </Section>
    </LayoutAlt>
  );
};
