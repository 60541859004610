/**
 * External dependencies.
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import Logo from 'components/logo/logo';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <img src={require('assets/images/footer.png')} alt="" />

      <div className="footer__inner">
        <div className="shell">
          <div className="footer__content">
            <Logo />

            <ul className="footer__links">
              <li>
                <a
                  href="https://radarxyz.notion.site/radarxyz/RADAR-Wiki-92959785c3174e5a91969d36a4336c29"
                  target="_blank"
                  rel="noreferrer"
                >
                  Wiki
                </a>
              </li>

              <li>
                <a
                  href="https://airtable.com/shrFKUzeNpJoDU0x9"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apply
                </a>
              </li>

              <li>
                <a
                  href="https://radarxyz.notion.site/SIGNAL-Token-a28c5061d07747148ed44cdb80a6686f"
                  target="_blank"
                  rel="noreferrer"
                >
                  Token - $signal
                </a>
              </li>

              <li>
                <a
                  href="https://airtable.com/shrFKUzeNpJoDU0x9"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Involved
                </a>
              </li>
            </ul>
          </div>

          <div className="footer__content-alt">
            <p>
              RADAR is a decentralised insight <br />
              and foresight network owned <br />
              by trendmakers.The future of futures.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
